import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "./../images/logo-synergistique.png";

import styled from "styled-components";

const Logo = styled.div`
  background: url('${logo}');
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: left;
`

const NavBar = styled.header`
  background: #fff;
  height: 70px;
  width: 100%;
  box-shadow: 0 3px 9px -3px rgba(88,88,88,.4);
  z-index: 10;
  position: fixed;
  
`

const NavBarContainer = styled.header`
  height: 100%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) {
      width: 90%;
  }

  a {
    width: 50%;
    height: 80%;
  }
`

const NavMenu = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    color: initial;
    height: 100%;
    
  }

  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) {
      display: none;
  }
`

const NavOption = styled.div`
  border-bottom: 2px solid #fff;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-bottom: 2px solid #EC7580;
  }
`;


const Header = () => (
  <NavBar>
    <NavBarContainer>
    <Link to="/"><Logo/></Link>
      <NavMenu>
        <Link to="/about"><NavOption>About</NavOption></Link>
        <Link to="/services"><NavOption>Services</NavOption></Link>
        <Link to="/careers"><NavOption>Careers</NavOption></Link>
        <Link to="/contact"><NavOption>Contact</NavOption></Link>
      </NavMenu>
    </NavBarContainer>
  </NavBar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
